import { FILTER_TYPES } from 'app/components/Table/tableConstants';

export const campaignShortcodesTableHeaders = [
  {
    field: '',
    displayName: null,
    width: '60px'
  },
  {
    field: 'short_code',
    displayName: 'Shortcode',
    filterType: FILTER_TYPES.TEXT,
    width: '200px'
  },
  {
    field: 'short_code_url',
    displayName: 'Shortcode URL',
    width: '300px'
  },
  {
    field: 'redirect_url',
    displayName: 'Redirect URL',
    filterType: FILTER_TYPES.TEXT,
    width: '300px'
  },
  {
    field: 'ignore_campaign_filter',
    displayName: 'Ignore Filter',
    filterType: FILTER_TYPES.TEXT,
    width: '150px'
  },
  {
    field: 'notes',
    displayName: 'Notes',
    filterType: FILTER_TYPES.TEXT,
    width: '250px'
  },
  {
    field: 'short_code_id',
    displayName: 'Shortcode ID',
    filterType: FILTER_TYPES.TEXT,
    width: '300px'
  }
];

export const rowsPerPage = 20;

export const downloadFormatActions = [
  {
    value: 'svg',
    name: 'SVG'
  },
  {
    value: 'png',
    name: 'PNG'
  }];