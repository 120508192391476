import classNames from 'classnames';
import React from 'react';
import styles from './Tooltip.module.scss';

interface Props {
  children: React.ReactNode;
  tooltip?: React.ReactNode;
  position?: string;
  customClassname?: boolean;
}

export default function Tooltip({ children, tooltip = null, position = 'default', customClassname }: Props): React.ReactElement {
  return (
    <div className={styles.root}>
      <div className={styles.trigger}>{children}</div>
      {tooltip &&
        <div
          className={classNames(
            styles.tooltip,
            styles[position],
            {
              [styles.large]: typeof tooltip === 'string' && tooltip.length >= 40,
              [styles.custom]: customClassname
            }
          )}
        >
          {tooltip}
        </div>}
    </div>
  );
}
